<template>
  <v-dialog :retain-focus="false" v-model="show" persistent max-width="300px">
    <v-card>
      <v-card-title class="text-h5">{{ title }}</v-card-title>

      <v-card-text>
        <v-form ref="newExchange" v-model="valid" class="d-flex">
          <v-autocomplete
            v-model="item.currency"
            class="mx-3"
            label="Currency"
            :items="itemsCurrency"
            :rules="[v => !!v || 'Currency is required']"
          >
          </v-autocomplete>
          <v-text-field
            v-model="item.money"
            prefix="$"
            required
            type="number"
            :rules="[v => !!v || 'Value is required']"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="secondary" @click="closeDialog">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          v-if="createForm"
          :disabled="!valid"
          @click="saveExchange"
          text
        >
          Add
        </v-btn>
        <v-btn
          color="primary"
          v-if="!createForm"
          :disabled="!valid"
          @click="saveExchange"
          text
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ExchangeForm",
  props: {
    item: Object,
    itemsCurrency: Array,
    title: String,
    value: Boolean,
    createForm: Boolean
  },
  data() {
    return {
      valid: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.$refs.newExchange.reset();
      this.$emit("closeDialog");
    },
    saveExchange() {
      this.$emit("saveExchange", this.item);
    }
  }
};
</script>
